/* stylelint-disable */
@import url("https://use.typekit.net/cjc5iof.css");

.root {
  position: relative;

  .timer {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN",
      "Arial", "Yu Gothic", "Meiryo", sans-serif;
    font-weight: bold;
    font-size: 7.74vw;
    letter-spacing: 0.6em;
    color: #332c02;
  }

  .hour,
  .min,
  .sec {
    bottom: 6%;
    position: absolute;

    &_piccomaTemplate {
      @extend .hour;
      bottom: 9%;
    }
  }

  .hour {
    right: 55%;

    &_piccomaTemplate {
      @extend .hour;
    }
  }

  .min {
    right: 34%;

    &_piccomaTemplate {
      @extend .min;
    }
  }

  .sec {
    right: 13.5%;

    &_piccomaTemplate {
      @extend .sec;
    }
  }

  .multiplier {
    font-size: 26vw;
    position: absolute;
    top: 42%;
    right: 60vw;
    transform: translateY(-50%);
    color: #ff2f00;
    text-align: right;
    font-style: normal;
    font-family: din-2014-narrow, sans-serif;
    font-weight: 800;
    width: 52vw;
    letter-spacing: -4px;
  }

  .image {
    img {
      width: 100%;
    }
  }
}

// 横画面用のスタイル
@media screen and (orientation: landscape) {
  .root {
    position: relative;

    .timer {
      width: 100%;
      display: flex;
      align-items: center;
      font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN",
        "Arial", "Yu Gothic", "Meiryo", sans-serif;
      font-weight: bold;
      font-size: 7.74vw;
      @media (min-width: 932px) {
        font-size: 73px;
      }
      letter-spacing: 3.5vw;
      @media (min-width: 932px) {
        letter-spacing: 35px;
      }
      color: #332c02;
    }

    .hour,
    .min,
    .sec {
      bottom: 4%;
      position: absolute;

      &_piccomaTemplate {
        @extend .hour;
        bottom: 7%;
      }
    }

    .hour {
      right: 54.6%;

      &_piccomaTemplate {
        @extend .hour;
      }
    }

    .min {
      right: 34%;

      &_piccomaTemplate {
        @extend .min;
      }
    }

    .sec {
      right: 13.5%;

      &_piccomaTemplate {
        @extend .sec;
      }
    }

    .multiplier {
      font-size: 26vw;
      @media (min-width: 932px) {
        font-size: 240px;
      }
      position: absolute;
      top: 42%;
      right: 50vw;
      @media (min-width: 932px) {
        right: 500px;
      }
      transform: translateY(-50%);
      color: #ff2f00;
      text-align: right;
      font-style: normal;
      font-family: din-2014-narrow, sans-serif;
      font-weight: 800;
      width: 52vw;
      letter-spacing: -4px;
    }

    .image {
      img {
        width: 100%;
      }
    }
  }
}
