/* フォントサイズ指定用 */
.huge {
  /* ブラウザデフォルトが16pxのとき→18px */
  font-size: 1.125rem;
}

.large {
  font-size: 1rem;
}

/* ブラウザデフォルトが16pxのとき→15px */
.mediumLarge {
  font-size: 0.9375rem;
}

.medium {
  /* ブラウザデフォルトが16pxのとき→14px */
  font-size: 0.875rem;
}

.small {
  /* ブラウザデフォルトが16pxのとき→12px */
  font-size: 0.75rem;
}

.tiny {
  /* ブラウザデフォルトが16pxのとき→11px */
  font-size: 0.6875rem;
}

/* 太字にする */
.bold {
  font-weight: bold;
}

/* 赤字にする */
.red {
  color: #dc143c;
}

.menuRed {
  color: #ff4c4c;
}

/* 下線つきにする */
.underlined {
  text-decoration: underline;
}

.warning {
  color: #f00;
  font-weight: bold;
  font-size: 0.6875rem;
}
