/* stylelint-disable-next-line */
$breakpoints: (
  "sp": "screen and (max-width: 639px)",
  "tab": "screen and (min-width: 640px) and (max-width: 1023px)",
  "pc": "screen and (min-width: 1024px)",
  "u-374": "screen and (max-width: 374px)",
) !default;

@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
