@import "../variables";

.offerList > a {
  text-decoration: none;
  color: #000;
}

.scroll {
  height: calc(#{$vh} - 88px);
  overflow: auto;
}
