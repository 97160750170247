.container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.75rem;
}

.timeSale {
  position: relative;

  .timer {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 6px;
    }

    &_remainTime {
      line-height: 0.6875rem;
      font-size: 0.6875rem;
      padding: 1px 0 1px;
    }
  }
}

.currencySale {
  max-width: 18.75rem;
  justify-content: space-between;
  font-weight: bold;
  padding: 0 0.9375rem;

  .timer {
    padding: 0.625rem 0;
  }
}

.clockIcon {
  height: 1rem;
}

.bonusIcon {
  height: 2.1875rem;
  width: 2.1875rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.1875rem;
}
