.before {
  text-decoration: line-through;
}

.arrow {
  margin: 0 2%;
}

.result {
  max-width: 18.75rem;
  margin: 0 auto;
}

.LandscapeBefore {
  display: flex;
  justify-content: center;
}

.LandscapeAfter {
  display: flex;
  justify-content: center;
}
