@import "../variables";

@keyframes slideUpIcon {
  to {
    bottom: $tripMileMenuHeight; /* メニューの高さに応じて変更 */
  }
}

.image {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  z-index: 2;
}

.torima {
  position: fixed;
  left: 12px;
  bottom: 12px;
  width: 64px;
  height: 68px;
  z-index: 10;
  animation-fill-mode: forwards;
}

.slideUpIcon {
  animation: slideUpIcon 0.5s ease;
  animation-fill-mode: forwards;
}
