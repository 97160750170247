.container {
  height: 7.88rem;
  width: 7.88rem;
  border-radius: 1rem;
  position: relative;

  img {
    height: 7.88rem;
    width: 7.88rem;
    max-width: max-content;
    max-height: max-content;
  }

  .footer {
    width: 100%;
    height: 1rem;
    position: absolute;
    bottom: 0;
    opacity: 0.9;
    border-radius: 0 0 0.25rem 0.25rem;

    &_text {
      font-weight: bold;
      font-size: 0.985rem;
    }
  }
}
