.surveyList > a {
  text-decoration: none;
  color: #000;
}

.unableToAnswersurveyList {
  filter: grayscale(100%);
}

.attention {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 4rem;
  padding: 10px 20px;
  background-color: #ebb501;

  img {
    height: 2rem;
    margin-right: 10px;
  }

  &_text {
    font-size: 0.75rem;
    font-weight: bold;
    color: #fff;
  }
}

.link {
  color: #808080;
}
