.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 80%;
    padding-bottom: 6rem;

    h1 {
      font-size: 1.625rem;
      margin-bottom: 2rem;
    }

    div {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 3rem;
    }

    button {
      width: 100%;
      font-size: 1.5rem;
      padding: 1.28125rem 0;
      background-color: #e0e0e0;
      border: none;
    }
  }
}
