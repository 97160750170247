.wrapper {
  padding: 0.625rem;
  border-bottom: 1px solid #e0e0e0;
}

.offer {
  width: 100%;
  display: flex;

  .featureArea {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;
  }

  .featureBox {
    width: 50px;
    height: 20px;
    border: 1px solid #326ac4;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
  }

  .featureText {
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ", sans-serif;
    font-size: 10px;
    font-weight: bold;
    color: #326ac4;
    opacity: 1;
    padding-top: 2px;
  }

  .featureBox_not {
    width: 50px;
    height: 20px;
    border: 1px solid #326ac4;
    border-radius: 5px;
    opacity: 1;
    float: left;
    text-align: center;
    vertical-align: middle;
    filter: grayscale(90%) opacity(50%);
  }

  .featureText_not {
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ", sans-serif;
    font-size: 10px;
    font-weight: bold;
    color: #326ac4;
    opacity: 1;
    padding-top: 2px;
    filter: grayscale(90%) opacity(50%);
  }

  .featureBoxFake {
    width: 50px;
    height: 20px;
    border-radius: 5px;
    float: left;
    text-align: center;
    vertical-align: middle;
    filter: opacity(100%);
  }

  .featureTextFake {
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ", sans-serif;
    font-size: 10px;
    font-weight: bold;
    color: #326ac4;
    opacity: 1;
    padding-top: 2px;
    filter: opacity(100%);
  }

  &_content {
    height: 5rem;
    width: calc(100% - 8.4rem);
    padding-left: 0.6rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;

      .info {
        width: calc(100% - 3.125rem);

        &_name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .recommendIcon {
        width: 3.125rem;

        img {
          height: 100%;
          width: 100%;
          vertical-align: middle;
          border-style: none;
          float: right;
        }
      }
    }

    &_button {
      width: 100%;
      height: 30px;
      padding: 3px;
    }
  }
}
