.footer {
  color: #a6a6a6;
}

.link {
  color: #a6a6a6;
}

.footer-text {
  padding: 22px 16px 25px;
  color: #a6a6a6;
}
