.slider {
  line-height: 0;
}

.slide {
  height: 100%;
  width: 100%;
}

.dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    cursor: pointer;

    button {
      display: block;
      height: 1.25rem;
      padding: 0.3rem;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &::before {
        font-family: "slick";
        font-size: 0.625rem;
        line-height: 1.25rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        content: "●";
        text-align: center;
        color: #aaa;
        opacity: 0.8;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}
