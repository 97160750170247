.container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

.timeSale {
  justify-content: space-around;
  position: relative;

  .timer {
    margin-left: 1.8rem;
  }
}

.currencySale {
  max-width: 18.75rem;
  justify-content: center;

  .timer {
    padding: 0.625rem 0;
  }
}

.clockIcon {
  height: 1rem;
  position: absolute;
  left: 0.8rem;
}

.bonusIcon {
  height: 2.1875rem;
  width: 2.1875rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.1875rem;
  margin-right: 0.6rem;
}
