@import "../variables";

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.container {
  height: $vh;
  width: 100%;
  overflow-y: scroll;
  // AndroidのMaterial Design対応（ナビゲーションドロワーはその他の要素より1レイヤー上げる）
}

.standerdContainer {
  height: $vh;
  width: 18.75rem;
  position: fixed;
  top: 0;
  z-index: 10;
  // AndroidのMaterial Design対応（ナビゲーションドロワーはその他の要素より1レイヤー上げる）
  box-shadow: 0 1px 6px 0 #000;
  overflow-y: scroll;
}

.menuLink {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuItem {
  padding: 1.125rem 0.625rem;
  border-bottom: 0.5px solid #eaeaea;
  position: relative;

  .next {
    color: #c0c6c8;
  }
}

.standerdMenuItem {
  padding: 1.125rem 0.625rem;
  border-bottom: 0.5px solid #fff;
  position: relative;

  .icon {
    font-size: 20px;
  }
}

.list {
  padding: 0 16px;
}

.image {
  width: 100%;

  &.is-piccoma-template {
    margin-top: 49px;
    display: inline-block;
  }
}

.menuList {
  padding: 0 1rem;
}

.menuScroll {
  overflow: auto;
  height: $mvh;
}

.header {
  height: 3.125rem;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: bold;
  justify-content: space-between;
  padding: 0 1rem;
}

.torimaContainer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 40%;
  width: 70%;
  z-index: 10;
  box-shadow: 0 1px 6px 0 #000;
  animation: slideUp 0.5s forwards;
}

.torimaMenuList {
  padding: 0 1rem;
  font-weight: 700;
}

.torimaMenuItem {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: solid;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の背景色 */
  z-index: 5; /* メニューの背面に表示 */
}
