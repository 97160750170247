/* ヘッダー */
.header {
  height: 3.125rem;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: bold;
  justify-content: space-between;
  padding: 0 1rem;
}

/* ボタン */
.button {
  border: none;
  border-radius: 0.3125rem;
}

/* ポイントアイコン */
.pointIcon {
  height: 1rem;
  margin-right: 2%;
}

/* 閉じるアイコン */
.closeIcon {
  position: absolute;
  top: 0;
  right: 0.2rem;
}

/* エラーメッセージ・警告用 */
.errorMessage {
  color: #dc143c;
  font-weight: bold;
  font-size: 0.6875rem;
}

/* cv条件 */
.cvCondition {
  height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 子要素をセンタリング */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* material-uiのアイコンはpointerにする */
.materialIcon {
  cursor: pointer;
}
