/* stylelint-disable scss/dollar-variable-pattern */

// height
$vh: calc(var(--vh) * 100);
// OSによっては表示が崩れるので、jsで高さを取りにいく用
$vhHalf: calc(var(---offer-top-url-var) * 50);
$vhTop: calc(var(---offer-top-url-var) * 100);
// 84pxは 48=ヘッダーの高さ、36=タブの高さ。
$bearTopH: calc(var(---offer-top-url-var) * 100 - 84px);
// 51pxは横画面表示時のヘッダのmin-height
$landBearTopH: calc(var(---offer-top-url-var) * 100 - 51px);
$bannerH: var(--banner-height);
$urlBar: var(--url-var);
$mvh: calc(var(---offer-top-url-var) * 100 - 48px);
// トリマFMTのメニューの高さ
$tripMileMenuHeight: var(--torip-mile-menu-height);
// 動画アイコンの横幅
$videoIconWidth: var(--video-icon-width);
