@import "../variables";

.overlay {
  width: 100vw;
  height: $vhTop;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.description {
  color: #fff;
  margin-top: 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.styled-slider-div {
  overflow: hidden;
}

.styled-slick-dots {
  position: relative;
  bottom: 0;
}

.carousel-image {
  height: $vhHalf;
  margin: 12px auto;
}

.carousel-image-last {
  height: 50svh;
  height: 50vh;
  margin: 12px auto;
}

.close-icon {
  height: 30px;
  width: 30px;
}

.button-wrapper {
  top: 50px;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.tutorial-content {
  overflow: hidden;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container {
  position: relative;

  .guide {
    max-width: 90%;
    margin: 1rem auto 0.31rem;

    img {
      max-height: 15.63rem;
    }
  }

  .description {
    width: 90%;
    min-height: 5.94rem;
    margin: 0 auto;

    &_main {
      padding-bottom: 0.31rem;
    }
  }

  .nav {
    width: 100%;
    padding: 1rem;
    background-color: #fff;

    .modalButton {
      width: 6.875rem;
      height: 2.8rem;
      font-weight: bold;
    }

    .back {
      background-color: #808080;
      color: #fff;
    }

    .transparent {
      opacity: 0%;
    }

    .indicator {
      max-height: 1rem;
      margin: 0 1rem 0;
    }
  }

  .container {
    height: 100svh;
    height: 100vh;
    overflow: auto;

    &-inner {
      height: 100%;
      min-height: 750px;
      padding: 15px 0 0;

      &::after {
        content: "";
        display: block;
        padding-bottom: calc(56px + $urlBar);
      }
    }
  }
}
