.button {
  border: none;
}

.primary {
  border: none;
  border-radius: 0.3125rem;
  font-weight: bold;
}

// 縦画面用のスタイル
@media screen and (orientation: portrait) {
  .secondary {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 0.3125rem;
    font-size: 0.875rem;
    font-family: "Helvetica";
    font-weight: bold;

    &.link {
      text-decoration: none;
    }
  }
}

// 横画面用のスタイル
@media screen and (orientation: landscape) {
  .secondary {
    width: 13rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 0.3125rem;
    font-size: 0.875rem;
    font-family: "Helvetica";
    font-weight: bold;
    float: right;

    &.link {
      text-decoration: none;
    }
  }
}

.tertiary {
  padding: 16px;
  border-radius: 24px;
  border: none;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
