.wrapper {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: bold;
  background-color: #fafafa;
  justify-content: space-between;
  padding: 12px 16px 12px;
  position: relative;
  z-index: 2;

  &.is-fixed {
    position: sticky;
    top: -1px;
    width: 100%;
    min-height: 51px;
  }

  .title {
    display: flex;
    margin: 0 auto;

    &__underline {
      text-decoration: none;
      display: flex;
      margin: 0 auto;
    }

    &__logo {
      height: 1.875rem;
    }

    &__text {
      font-size: 18px;
      align-items: center;
      white-space: nowrap;
      display: inline-block;
    }
  }

  .close {
    width: 24px;
    height: 24px;
  }

  .closeLink {
    display: flex;
    align-items: center;
  }
}

.underbar {
  border-bottom: 0.5px solid #e5e5e5;
}
