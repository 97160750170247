.container {
  height: 4.625rem;
  width: 4.625rem;
  border-radius: 1rem;

  img {
    max-width: none;
    border-radius: 21%;
    width: 74px;
    height: 74px;
  }

  &.is-index {
    width: 64px;
    height: 64px;

    img {
      width: 64px;
      height: 64px;
    }
  }
}
