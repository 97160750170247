.wrapper {
  padding: 0.625rem;
  border-bottom: 1px solid #e0e0e0;
}

.offer {
  width: 100%;
  display: flex;

  &_content {
    height: 5rem;
    width: calc(100% - 6.75rem);
    padding-left: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &_upper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        width: calc(100% - 3.125rem);

        &_name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .recommendIcon {
      width: 3.125rem;
      position: absolute;
      top: 0;
      right: 0;

      img {
        height: 100%;
        width: 100%;
        vertical-align: middle;
        border-style: none;
        float: right;
      }
    }

    &_button {
      width: 100%;
      min-height: 2.18rem;
      padding: 3px;
    }

    &_tripMileButton {
      width: 100%;
      min-height: 2.18rem;
      padding: 3px;
      box-shadow: 0 0.3rem #4c3575;
      border-radius: 3rem;
    }
  }
}
