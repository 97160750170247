.button {
  border: none;
}

.primary {
  border-radius: 0.3125rem;
}

.secondary {
  border-radius: 0.3125rem;
  width: 100%;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.link {
    text-decoration: none;
  }
}
