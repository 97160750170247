@import "./reset";
@import "./variables";

#root {
  .swiper-wrapper {
    height: auto !important;
  }
}

.bear {
  body {
    position: static !important;
    overflow: auto !important;
  }
}

.is-fixed {
  height: $vh;
  height: -webkit-fill-available;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden !important;

  #root {
    height: 100%;
  }
}

.navigation-item {
  display: none;
}
