.root {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.track {
  width: 100%;
  display: flex;
  min-width: 1000px;

  & > * {
    width: 100%;
    height: 100%;
    min-height: 1px;
  }
}

.is-mouse {
  & > div {
    height: 100%;
    overflow: hidden;
  }
}
