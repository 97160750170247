.description {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;

  .titleWrapper {
    padding-left: 16px;

    .title {
      font-size: 14px;
      margin-right: 4px;
      font-weight: bold;
    }

    .subtitle {
      font-size: 12px;
    }
  }

  .showAll {
    font-size: 12px;
    font-weight: bold;
    color: #b3b3b3;
    text-decoration: none;

    .showMore-link-wrapper {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }
  }
}

.lists {
  display: flex;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  a:first-child {
    margin-left: 16px;
  }
}

.noChallenge {
  width: 100%;
  text-align: center;
  padding: 48px 0 32px 0;
}

.link {
  color: #282828;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.icon {
  margin-right: 20px;

  .coin-wrapper {
    margin-top: 2px;
    display: flex;
    align-items: center;
  }
}

.offer-type-txt {
  font-family: "ヒラギノ角ゴシック";
  font-size: 9px;
  font-weight: normal;
  width: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sur {
  font-family: "Helvetica";
}

.coin {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}

.iconStyle {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}
