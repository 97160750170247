@import "./variables";

/* ボタン */
.button {
  border: none;
  border-radius: 0.3125rem;
  font-weight: bold;
}

/* ポイントアイコン */
.pointIcon {
  height: 1rem;
  margin-right: 2%;
}

/* 閉じるアイコン */
.closeIcon {
  position: absolute;
  top: 0;
  right: 0.2rem;
}

/* エラーメッセージ・警告用 */
.errorMessage {
  color: #dc143c;
  font-size: 12px;
}

/* cv条件 */
.cvCondition {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 3rem);
  display: block;
}

/* 子要素をセンタリング */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 子要素を右よせ */
.right {
  display: flex;
  justify-content: right;
  align-items: center;
}

/* material-uiのアイコンはpointerにする */
.materialIcon {
  cursor: pointer;
}

/* スクロールのコンテナ */
.scrollContainer {
  overflow: auto;
  height: calc(#{$vh} - 48px - 36px - 0.5px);
}

.container {
  padding: 48px 0 56px;
}
