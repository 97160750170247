@import "../mediaQuery";
@import "../variables";

.overlay {
  width: 100vw;
  height: $vhTop;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.wrapper {
  .link {
    text-decoration: none;
    margin: 12px auto;

    .descriptionWrapper {
      border-radius: 0 0 16px 16px;
      width: 305px;
      padding: 16px;
      justify-content: space-between;
      align-items: center;

      .description {
        display: flex;

        .title {
          font-family: "ヒラギノ角ゴシック";
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          width: 153px;
        }

        .offerTileContainer {
          align-items: center;

          .offerTileType {
            font-size: 11px;
            padding: 0 0 7px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 160px;
          }

          .point {
            font-family: "Helvetica";
            font-weight: bold;
            font-size: 15px;
          }
        }
      }

      .offerTileButton {
        padding: 10px 14px;
        font-size: 12px;
        width: 273px;
        height: 44px;
      }
    }
  }

  .image {
    // 横750:縦390のアスペクト比で固定する
    height: 175px;
    width: 305px;
    border-radius: 16px 16px 0 0;
  }
}

.pointdescription {
  display: flex;
  float: right;
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 15px;
  justify-content: right;
}

.point-title {
  font-family: "ヒラギノ角ゴシック";
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
}

.offer-description {
  color: #fff;
  font-size: 16px;
  font-family: "ヒラギノ角ゴシック";
  font-weight: 600;
  text-align: center;
  margin: 0 0 24px 0;
}

.icon {
  width: 15px;
  height: 15px;
  margin-right: 2px;
}

.offer-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.close-icon {
  height: 30px;
  width: 30px;
}

.button-wrapper {
  top: 50px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.detailButton {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  padding-bottom: 0;
}

.detailButtonTripMile {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.75rem;
  padding-bottom: 0;
  box-shadow: 0 0.3rem #4c3575;
  border-radius: 3rem;
}
