@import "../variables";

@media screen and (orientation: portrait) {
  .pikkoma-tabs {
    height: 36px;
    display: flex;
    padding: 0 0.625rem;
    position: sticky;
    z-index: 1;
    border-bottom: 0.5px solid #e5e5e5;

    &.is-active {
      position: sticky;
      width: 100%;
      top: 48px;
    }
  }

  .torima-tabs {
    height: 40px;
    display: flex;
    padding: 1px 0.625rem 0;
    position: sticky;
    // AndroidのMaterial Design対応（タブはその他の要素より1レイヤー上げる）
    box-shadow: 0 1px 6px 0 #707070;
    overflow: auto;

    &.is-active {
      position: sticky;
      width: 100%;
    }
  }

  .standerd-tabs {
    height: 3.125rem;
    display: flex;
    padding: 1px 0.625rem 0;
    top: -1px;
    z-index: 1;
    position: sticky;
    // AndroidのMaterial Design対応（タブはその他の要素より1レイヤー上げる）
    box-shadow: 0 1px 6px 0 #707070;

    &.is-active {
      position: sticky;
      width: 100%;
      top: 47px;
    }
  }

  .banner-vertical {
    display: block;
  }

  .banner-horizontal {
    display: none;
  }

  .slider-contents-tabs {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .slider-contents-tabs::-webkit-scrollbar {
    display: none;
  }

  .tabs_over {
    cursor: pointer;
    flex: 0 0 calc(100% / 5.6);
    scroll-snap-align: start;
  }

  .tab {
    width: 25%;
    cursor: pointer;

    &_title {
      height: 100%;
      width: 100%;
      margin: 0 1%;
      padding: 0 1%;
      white-space: nowrap;
    }
  }

  .selected_tab {
    border-bottom: 2px solid;
  }

  .scrolltab {
    width: 25%;
    cursor: pointer;

    &_title {
      height: 100%;
      width: 100%;
      padding: 0 1%;
      white-space: nowrap;
      margin: 0 4%;
    }
  }

  .background {
    background-color: #e5e5e5;

    &.is-pickup {
      padding-top: 48px;
    }
  }

  .swiper {
    z-index: 0;
  }

  .currencyClockContainer {
    position: fixed;
    bottom: 1rem;
    width: 100vw;
  }

  .listWrapper {
    margin-bottom: 6px;
    padding: 30px 0;

    .list-container {
      border-bottom: 1px solid #eaeaea;
      padding: 20px 0;
    }

    .list-container:first-of-type {
      padding: 0 0 20px;
    }

    .list-container:last-of-type {
      padding: 20px 0 0;
      border-bottom: none;
    }
  }

  .slider-contents {
    overflow: auto;
    height: $bearTopH !important;
    min-width: 100vw;
    position: relative;
    z-index: 1;
  }

  .slider-contents-pickup {
    height: 0;
    overflow: visible;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0) !important;
    min-width: 100vw;

    &.is-active {
      height: auto;
    }
  }

  .banner {
    margin-bottom: 6px;
  }

  .no-challenge {
    width: 100%;
    text-align: center;
    padding: 48px 0 32px;
  }
}

// 横画面用のスタイル
@media screen and (orientation: landscape) {
  .container {
    max-width: 932px;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-body {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .banner-vertical {
    display: none;
  }

  .banner-horizontal {
    display: block;
  }

  .menu-body-right {
    width: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .pikkoma-tabs {
    width: 6.25rem;
    display: flex;
    flex-direction: column;
    position: sticky;
    height: calc(100vh - 52px);
    z-index: 1;
    border-bottom: 0.5px solid #e5e5e5;

    &.is-active {
      width: 6.25rem;
      top: 52px;
    }
  }

  .torima-tabs {
    width: 6.25rem;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    height: 100vh;
    z-index: 1;
    // AndroidのMaterial Design対応（タブはその他の要素より1レイヤー上げる）
    box-shadow: 0 1px 6px 0 #707070;
  }

  .standerd-tabs {
    width: 6.25rem;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 52px;
    height: calc(100vh - 52px);
    z-index: 1;
    // AndroidのMaterial Design対応（タブはその他の要素より1レイヤー上げる）
    box-shadow: 0 1px 6px 0 #707070;
  }

  .slider-contents-tabs {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .slider-contents-tabs::-webkit-scrollbar {
    display: none;
  }

  .tabs_over {
    cursor: pointer;
    flex: 0 0 calc(100% / 5.6);
    scroll-snap-align: start;
  }

  .tab {
    width: 25%;
    cursor: pointer;

    &_title {
      flex: 1;
      padding: 0 1%;
      white-space: nowrap;
    }
  }

  .selected_tab {
    border-right: 3px solid;
  }

  .scrolltab {
    width: 25%;
    cursor: pointer;

    &_title {
      height: 100%;
      width: 100%;
      padding: 0 1%;
      white-space: nowrap;
      margin: 0 4%;
    }
  }

  .background {
    background-color: #e5e5e5;

    &.is-pickup {
      padding-top: 48px;
    }
  }

  .swiper {
    z-index: 0;
  }

  .currencyClockContainer {
    position: fixed;
    bottom: 1rem;
    max-width: 932px;
    width: 100%;
  }

  .listWrapper {
    margin-bottom: 6px;
    padding: 30px 0;

    .list-container {
      border-bottom: 1px solid #eaeaea;
      padding: 20px 0;
    }

    .list-container:first-of-type {
      padding: 0 0 20px;
    }

    .list-container:last-of-type {
      padding: 20px 0 0;
      border-bottom: none;
    }
  }

  .slider-contents {
    overflow: auto;
    height: $landBearTopH !important;
  }

  .slider-contents-pickup {
    height: 0;
    overflow: visible;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0) !important;

    &.is-active {
      height: auto;
    }
  }

  .banner {
    margin-bottom: 6px;
  }

  .no-challenge {
    width: 100%;
    text-align: center;
    padding: 48px 0 32px;
  }
}
