.wrapper {
  padding: 0.625rem;
  border-bottom: 1px solid #e0e0e0;
}

.offer {
  width: 100%;
  display: flex;

  &_content {
    height: 8.1rem;
    width: calc(100% - 8rem);
    padding-left: 1.3rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &_upper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        width: calc(100% - 3.125rem);

        &_name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .recommendIcon {
        width: 3.125rem;

        img {
          height: 100%;
          width: 100%;
          vertical-align: middle;
          border-style: none;
          float: right;
        }
      }
    }

    .borderOnly {
      width: 100%;
      border-top: 0.20000000298023224px solid #d7d7d7;
      filter: opacity(80%);
    }

    .featureArea {
      display: flex;
      justify-content: space-between;
    }

    .featureBox {
      width: calc(100% - 68%);
      height: 26px;
      border: 1px solid #326ac4;
      border-radius: 5px;
      opacity: 1;
      float: left;
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .featureBox_not {
      width: calc(100% - 68%);
      height: 26px;
      border: 1px solid #326ac4;
      border-radius: 5px;
      opacity: 1;
      float: left;
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      filter: grayscale(90%) opacity(50%);
    }

    .featureBoxFake {
      width: calc(100% - 68%);
      height: 26px;
      border: 1px #e0e0e0;
      border-radius: 5px;
      opacity: 1;
      float: left;
      text-align: center;
      vertical-align: middle;
      filter: opacity(0%);
    }

    .featureText {
      font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ",
        sans-serif;
      font-size: 13px;
      font-weight: bold;
      color: #326ac4;
      line-height: 26px;
    }

    .featureText_not {
      font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ",
        sans-serif;
      font-size: 13px;
      font-weight: 900;
      color: #326ac4;
      line-height: 26px;
      filter: grayscale(90%) opacity(50%);
    }

    .featureTextFake {
      font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ",
        sans-serif;
      font-size: 13px;
      font-weight: 900;
      color: #326ac4;
      line-height: 26px;
      filter: opacity(0%);
    }

    &_button {
      width: 100%;
      min-height: 2.18rem;
      padding: 3px;
    }
  }
}
