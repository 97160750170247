span {
  font-family: "Helvetica";
}

.before {
  text-decoration: line-through;
  color: #b3b3b3;
  margin-right: 0.5rem;
}

.icon {
  width: 16px;
  height: 16px;
}

.after {
  min-width: auto;
}

.arrow {
  margin: 0 2%;
}

.result {
  max-width: 18.75rem;
  font-size: 15px;
  white-space: pre-wrap;
}
