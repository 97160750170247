.root {
  white-space: nowrap;
}

.text {
  height: 100%;
  width: 100%;
}

.primary {
  color: #fff;
  font-family: "ヒラギノ角ゴ Pro W3";
  font-weight: bold;
  padding: 0 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 10px;
}

.secondary {
  color: #fff;
  font-family: "ヒラギノ角ゴ Pro W3";
  font-weight: bold;
  display: flex;
  width: 30px;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  font-size: 10px;
}

.centeredContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
