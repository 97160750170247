@import "../mediaQuery";

.wrapper {
  padding: 16px;
  border-bottom: 1px solid transparent;
  position: relative;

  &_notlast {
    padding: 16px;
    border-bottom: 1px solid transparent;
    position: relative;

    &::after {
      content: "";
      display: block;
      line-height: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      bottom: -1px;
      width: calc(100% - 16px);
      border-bottom: 1px solid #eaeaea;
    }
  }
}

.menu-wrapper {
  padding: 1.25rem 1rem;
  border-bottom: 6px solid #e5e5e5;
}

.offer {
  width: 100%;
  display: flex;

  &_content {
    height: auto;
    padding-left: 1rem;
    flex: 1;
    position: relative;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;

    &_upper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .info {
        width: 100%;

        &_name {
          font-weight: 300;
          line-height: 0.875rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 7px;

          @include mq(u-374) {
            width: 170px;
          }
        }

        &_image {
          position: absolute;
          top: 0;
          right: 5px;
          height: 22px;
        }

        &_app-name {
          font-weight: 300;
          line-height: 1.3rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 7px;

          @include mq(u-374) {
            width: 170px;
          }
        }

        &_menu-name {
          width: 260px;
          font-weight: 300;
          line-height: 0.875rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &_cv {
          line-height: 0.6875rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .detail {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
        }

        .offer-type-txt {
          margin-top: 10px;
          font-family: "ヒラギノ角ゴシック";
          font-size: 10px;
          font-weight: normal;
          color: #666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .sur {
          font-family: "Helvetica";
        }
      }

      .recommendIcon {
        max-width: 3rem;

        img {
          width: 100%;
          vertical-align: middle;
          border-style: none;
          float: right;
        }
      }
    }

    &_button {
      width: 100%;
      height: 44px;
      margin-top: 20px;
    }

    &_transition-destination-text {
      text-align: right;
      font-size: 0.6rem;
      margin-top: 10px;
    }
  }
}

.clock {
  width: max-content;
  margin-bottom: 6px;
}

.point {
  line-height: 15px;
  color: #282828;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin: 15px 0 0 auto;
}

.pointResult {
  display: -webkit-inline-box;
  position: absolute;
  right: 0;
  bottom: 0;
}

.pointUnit {
  font-size: 10px;
  font-weight: bold;
}

.coin {
  margin-right: 3px;
  height: 11px;
  width: 11px;
  max-width: none;
}
