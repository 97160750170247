// 縦画面用のスタイル
@media screen and (orientation: portrait) {
  //共通スタイル
  .skipButton {
    background-color: transparent;
    color: #fff;
    width: 70px;
    border: 1px solid #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  .skipButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .errorText {
    color: #fff;
    width: 70%;
    margin: 60px auto 0;
    background-color: #ff4c4c;
    font-size: 12px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  //skip用スタイル
  .skipContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .skipCard {
    width: 300px;
    background-color: #fff;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }

  .skipText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .skipConfirmButton {
    background-color: #282828;
    color: #fff;
    width: 260px;
    height: 36px;
    border: 1px solid #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 10px;
  }

  .backButton {
    background-color: #ff4c4c;
    color: #fff;
    width: 260px;
    height: 36px;
    border: 1px solid #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 10px;
  }

  //top用スタイル
  .tutorialOffer {
    a {
      text-decoration: none;
      color: #000;
    }
  }

  .topContainer {
    transform: translateY(-50px);
  }

  .topSpeechBubble {
    background: #fff;
    border-radius: 10px;
    padding: 5px;
    width: 95%;
    max-width: 500px;
    height: auto;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-15px);
  }

  .topSpeechBubble::after {
    content: "";
    position: absolute;
    bottom: -10px; /* 矢印の位置を調整 */
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 20px; /* 矢印の大きさ */
    height: 20px;
    background: #fff;
    z-index: -1;
  }

  .tutorialImg {
    margin: 60px auto 0 auto;
  }

  .topFinger {
    position: fixed;
    left: 45%;
    transform: translateY(-20px) rotate(30deg);
  }

  .bigImageTopFinger {
    position: fixed;
    right: 0;
    transform: translateX(-100px) translateY(-90px) rotate(60deg);
  }

  //detail用スタイル
  .modal {
    .detailSpeechBubble {
      margin-top: 100px;
      position: relative;
      background: #fff;
      border-radius: 10px;
      padding: 5px;
      width: 95%;
      max-width: 500px;
      height: auto;
      text-align: center;
      margin-bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }

    .detailSpeechBubble::after {
      content: "";
      position: absolute;
      bottom: -10px; /* 矢印の位置を調整 */
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 20px; /* 矢印の大きさ */
      height: 20px;
      background: #fff;
      z-index: -1;
    }

    .getBonusImage {
      width: 48vh;
      height: 8vh;
      max-width: 300px;
      max-height: 50px;
      margin: 30px auto 0 auto;
    }

    .presentsImage {
      width: 38vh;
      height: 32vh;
      max-width: 480px;
      max-height: 400px;
      margin: 0 auto;
    }

    .detailFinger {
      position: fixed;
      left: 40%;
      transform: translateX(-50%) rotate(20deg) translateY(-10px);
    }

    .clickEventButton {
      width: 90%;
      margin: 0 auto;
    }

    .getBonusCard {
      width: 90%;
      max-width: 370px;
      background-color: #fff;
      padding: 4px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      margin: 0 auto 10px;
      text-align: center;
    }

    .getBonusButton {
      background-color: #ff4c4c;
      color: #fff;
      width: 260px;
      height: 36px;
      border: 1px solid #fff;
      font-size: 16px;
      cursor: pointer;
      margin: 10px auto 0;
      display: block;
    }

    .fitchCard {
      width: 90%;
      background-color: #fff;
      padding: 16px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      margin: 60px auto 10px;
      align-items: center;
    }
  }
}

// 横画面用のスタイル
@media screen and (orientation: landscape) {
  //共通スタイル
  .skipButton {
    background-color: transparent;
    color: #fff;
    width: 70px;
    border: 1px solid #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  .skipButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .errorText {
    color: #fff;
    width: 350px;
    margin: 0 auto;
    background-color: #ff4c4c;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    padding: 10px;
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  //skip用スタイル
  .skipContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .skipCard {
    width: 300px;
    background-color: #fff;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }

  .skipText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .skipConfirmButton {
    background-color: #282828;
    color: #fff;
    width: 260px;
    height: 36px;
    border: 1px solid #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 10px;
  }

  .backButton {
    background-color: #ff4c4c;
    color: #fff;
    width: 260px;
    height: 36px;
    border: 1px solid #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 10px;
  }

  //top用スタイル

  .topSpeechBubble {
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding: 1px;
    width: 75%;
    max-width: 600px;
    height: 85px;
    text-align: center;
    transform: translateY(-5px);
    margin: 0 auto;
  }

  .topSpeechBubble::after {
    content: "";
    position: absolute;
    bottom: -5px; /* 矢印の位置を調整 */
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px; /* 矢印の大きさ */
    height: 10px;
    background: #fff;
    z-index: -1;
  }

  .tutorialImg {
    margin: 0 auto;
    height: 30vh;
    max-height: 200px;
    min-width: 250px;
    max-width: 600px;
  }

  .topFinger {
    position: fixed;
    left: 65%;
    transform: translateY(100px) rotate(40deg);
  }

  //　指が見切れそうになると移動させる
  @media screen and (max-height: 400px) {
    .topFinger {
      transform: translateX(-65%) translateY(5vh) rotate(120deg);
    }
  }

  .bigImageTopFinger {
    position: fixed;
    transform: translateX(200px) translateY(170px) rotate(30deg);
  }

  .tutorialOffer {
    position: fixed;
    width: calc(100% - 6.25rem);
    max-width: 830px;
    left: 50%;
    transform: translateX(calc(-50% + 50px));

    a {
      text-decoration: none;
      color: #000;
    }
  }

  //detail用スタイル
  .modal {
    max-width: 932px;
    margin-left: auto;
    margin-right: auto;

    .getBonusImage {
      width: 60vh;
      max-width: 500px;
      height: 10vh;
      min-height: 15px;
      max-height: 100px;
      margin: 10px auto 0 auto;
    }

    .presentsImage {
      width: 30vh;
      max-width: 500px;
      height: 30vh;
      margin: 0 auto;
    }

    .floatRight {
      float: right;

      .detailSpeechBubble {
        position: relative;
        background: #fff;
        border-radius: 10px;
        padding: 5px;
        width: 400px;
        height: auto;
        text-align: center;
        top: 60px;
        margin-bottom: 13px;
        transform: translateX(-35%);
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .detailSpeechBubble::after {
        content: "";
        position: relative;
        bottom: -10px; /* 矢印の位置を調整 */
        left: 50%;
        transform: translateY(-150%) rotate(45deg);
        width: 20px; /* 矢印の大きさ */
        height: 20px;
        background: #fff;
        z-index: -1;
      }

      .clickEventButton {
        width: 13rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(100%) translateY(-20px);
      }

      .detailFinger {
        float: right;
        transform: translateX(-250%) translateY(-30px) rotate(20deg);
      }
    }
  }

  .getBonusCard {
    width: 90%;
    max-width: 400px;
    height: 70px;
    background-color: #fff;
    padding: 5px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 10px;
    text-align: center;
  }

  .getBonusButton {
    background-color: #ff4c4c;
    color: #fff;
    width: 260px;
    height: 36px;
    border: 1px solid #fff;
    font-size: 16px;
    cursor: pointer;
    margin: 10px auto 0;
    display: block;
  }

  .fitchCard {
    width: 90%;
    background-color: #fff;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 10px;
    align-items: center;
    transform: translateY(200px);
  }
}
