@media screen and (orientation: portrait) {
  .wrapper {
    padding: 0.625rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .offer {
    width: 100%;
    display: flex;

    &_content {
      height: 5rem;
      width: calc(100% - 6.75rem);
      padding-left: 1rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_upper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info {
          width: calc(100% - 3.125rem);

          &_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &_cv {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
        }

        img {
          height: 100%;
          width: 100%;
          vertical-align: middle;
          border-style: none;
          float: right;
        }
      }

      &_button {
        width: 100%;
        min-height: 2.18rem;
        padding: 3px;
      }

      &_transition-destination-text {
        text-align: right;
        font-size: 0.6rem;
        margin-top: 10px;
      }
    }
  }
}

// 横画面用のスタイル
@media screen and (orientation: landscape) {
  .wrapper {
    padding: 0.625rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .offer {
    width: 100%;
    display: flex;

    &_content {
      height: 5rem;
      width: calc(100% - 6.75rem);
      padding-left: 1rem;
      flex: 1;
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-bottom: 10px;

      &_upper {
        order: 1;
        flex-grow: 1;
        max-width: calc(100% - 13rem - 5rem); /* 全体からbuttonとrecommendIconの合計幅を引く */
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info {
          width: 95%;

          &_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &_cv {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
        }

        img {
          height: 100%;
          width: 100%;
          vertical-align: middle;
          border-style: none;
          float: right;
        }
      }

      &_button {
        order: 2;
        width: 13rem;
        min-height: 2.18rem;
        padding: 3px;
      }

      &_transition-destination-text {
        max-width: calc(100% - 5rem); /* 全体からrecommendIconの合計幅を引く */
        text-align: right;
        font-size: 0.6rem;
        margin-top: 10px;
      }
    }
  }
}
