@import "../mediaQuery";
@import "../variables";

.overlay {
  width: 100vw;
  height: $vhTop;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.wrapper {
  width: 305px;
  border-radius: 16px;
  overflow: hidden;

  .link {
    text-decoration: none;

    .descriptionWrapper {
      padding: 16px;
      justify-content: space-between;
      align-items: center;

      .description {
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
          font-family: "ヒラギノ角ゴシック";
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: bold;
          font-size: 19px;
        }
      }
    }
  }
}

.icon {
  width: 15px;
  height: 15px;
  margin-right: 2px;
}

.offer-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.close-icon {
  height: 30px;
  width: 30px;
}

.button-wrapper {
  top: 50px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.detailButton {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  padding-bottom: 0;
  word-break: break-all;
}

// 上にdescriptionが表示されている場合のみmarginを適用する
.description + .detailButton {
  margin-top: 10px;
}

.detailButtonTripMile {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.75rem;
  padding-bottom: 0;
  box-shadow: 0 0.3rem #4c3575;
  border-radius: 3rem;
  word-break: break-all;
}

// 上にdescriptionが表示されている場合のみmarginを適用する
.description + .detailButtonTripMile {
  margin-top: 10px;
}
